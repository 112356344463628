<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
                <el-breadcrumb-item>运营管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addBtn">创建模板</el-button>
        </div>
        <el-table :data="opModuleList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="运营推广名称" width="300" :resizable="false"></el-table-column>
            <el-table-column prop="extend" align="center" label="运营模块" :resizable="false">
                <template slot-scope="scope">
                    <span v-for="item in scope.row.extend">
                        {{(item.op_module_type === 1) ? '店铺选品' : ((item.op_module_type === 2) ? '店铺营销' : ((item.op_module_type === 3) ? '搜索广告' : '展位广告'))}}（{{item.op_module_percent}}%）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200" :resizable="false">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "OperateData",
        data() {
            return {
                //热门关键词列表
                opModuleList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getOpModuleList();
        },
        methods: {
            //获取运营模板列表
            getOpModuleList() {
                let param = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.showOperationList, param, (res) => {
                    if (res.code === 200) {
                        this.opModuleList = res.data.data;
                        this.listPages.total = res.data.total;
                        if (this.listPages.total !== 0 && this.opModuleList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getOpModuleList();
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //创建模板
            addBtn() {
                this.$router.push('/admin/operatedata/operationmanage/create')
            },
            //编辑
            editBtn(row) {
                this.$router.push({
                    path: '/admin/operatedata/operationmanage/create',
                    query: {
                        id: row.id
                    }
                })
            },
            //删除
            delBtn(id) {
                this.$confirm('是否删除该模板，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delOperation, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getOpModuleList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getOpModuleList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>